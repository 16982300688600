.appWrapper,
.previewWrapper {
  display: block;
  position: relative;
}

.previewWrapper {
  background: #fff;

  &.addPadding {
    box-sizing: border-box;
    padding: 10px;
  }
}

.app {
  font-size: 16px;
  font-family: Arial, sans-serif;
  width: 100%;
  color: #000;
  background: #fff;
  box-sizing: border-box;
  margin-bottom: 16px;
  display: block;
  position: relative;
  text-decoration: none !important;

  .article & {
    margin-bottom: 0;
  }
}

.headline {
  display: block;
  font-family: 'Graphik', Arial, sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.15;
  color: #004db3;
  margin-bottom: 16px;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    color: #df173f;
    text-decoration: none;
  }

  .mobileStyle & {
    font-family: Georgia, serif;
    font-weight: normal;
  }
}

.previewText {
  font-family: 'Graphik', Arial, sans-serif;
  font-size: 14px;
  line-height: 1.3;
  color: #000;
  margin-top: 12px;
  width: 100%;
  box-sizing: border-box;
}

.heading {
  background: #333;
  color: #fff;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 1.1;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  width: 205px;
  height: 51px;
  margin-right: 8px;
}

.subheading {
  width: 90px;
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.1;
}

.content {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  border: 1px solid #b9b7b9;
  border-top: none;
  min-height: 300px;
}

.resultList {
  width: 100%;
}

.error {
  font-size: 13px;
  color: #de2148;
  margin-top: 4px;
}

.vanityText {
  width: 114px;
  text-align: right;
  margin-right: 12px;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  padding-bottom: 4px;
}

.viewMore {
  position: absolute;
  bottom: 1px;
  width: calc(100% - 2px);
  left: 1px;
  background-color: rgba(224, 29, 51, 0.9);
  text-align: center;
  line-height: 28px;
  height: 28px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #7a000f;

  .viewMoreChevron {
    color: #df173f;
    font-weight: normal;
    display: block;
    height: 10px;
    width: 6px;
    background: url(./assets/chevronRight.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-left: 4px;
  }

  .app:hover & {
    background-color: rgba(156, 20, 46, 0.9);
  }
}

.tabContainer {
  position: absolute;
  width: 100%;
  top: 77px;
  z-index: 10;
}

.buttonSelectors {
  display: flex;
  flex-direction: row;
  border-bottom: 3px solid #e2e2e2;

  &[data-current-tab='news'] {
    border-color: #333;
  }

  &[data-current-tab='sport'] {
    border-color: #00c36e;
  }

  &[data-current-tab='entertainment'] {
    border-color: #d10a34;
  }

  &[data-current-tab='newYork'] {
    border-color: #000080;
  }
}

.tabSelector {
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  padding: 0 14px;
  padding-top: 2px;
  height: 28px;
  min-width: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #999;
  font-weight: bold;
  position: relative;
  opacity: 0.4;
  margin: 0;

  &[data-tab-name='news'] {
    background-color: #333;
  }

  &[data-tab-name='sport'] {
    background-color: #00c36e;
  }

  &[data-tab-name='entertainment'] {
    background-color: #d10a34;
  }

  &[data-tab-name='newYork'] {
    background-color: #000080;
  }

  &:not(:first-of-type) {
    margin-left: 4px;
  }

  &:hover,
  &.selectedTab {
    opacity: 1;
  }

  &.selectedTab {
    cursor: default;
  }

  &[disabled] {
    cursor: disabled;
  }

  @media (hover: none) {
    &:hover:not(.selectedTab) {
      opacity: 0.4;
    }
  }
}

.debugInfo {
  margin: 10px;
  padding: 10px;
  background: #eee;
}

.wide {
  .content {
    padding: 10px 16px;
    padding-top: 56px;
  }

  .heading {
    font-size: 20px;
    padding: 9px;
    padding-left: 17px;
    padding-bottom: 5px;
    justify-content: center;
  }

  .logo {
    margin-right: 12px;
    width: 237px;
    height: 59px;
  }

  .subheading {
    width: 114px;
    font-size: 20px;
    line-height: 1.25;
    padding-bottom: 4px;
  }

  .tabContainer {
    width: calc(100% - 34px);
    left: 17px;
    top: 83px;
  }

  .previewText {
    margin-top: 5px;
  }
}

.narrow {
  .headline {
    font-size: 26px;
  }

  .content {
    padding: 10px;
    padding-bottom: 0;
    padding-top: 60px;
  }

  .heading {
    font-size: 16px;
    padding-bottom: 6px;
  }

  .subheading {
    font-size: 16px;
    padding-bottom: 4px;
  }

  .tabContainer {
    width: calc(100% - 22px);
    left: 11px;
    top: 80px;
  }
}

.smallTabs {
  .tabSelector {
    min-width: auto;
    font-size: 11px;
    padding: 0 6px;
  }
}

.promo {
  .content {
    border-top: none;
  }
}

.mpu .content,
.mpuSmall .content,
.bellyband .content {
  min-height: auto;
}

.article {
  &.previewWrapper {
    margin-bottom: 16px;
  }

  .content {
    border-top: 1px solid #b9b7b9;
  }

  .viewMoreWithLogo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    &::after {
      display: block;
      content: '';
      position: absolute;
      top: -80px;
      height: 80px;
      width: calc(100% - 2px);
      left: 1px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    }
  }

  .viewMoreButton {
    border-radius: 3px;
    background-color: #df173f;
    font-weight: bold;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  &.narrow {
    .headline {
      margin-bottom: 6px;
    }

    .previewText {
      margin-top: 6px;
    }

    .content {
      padding-top: 50px;
      padding-bottom: 30px;
    }

    .tabContainer {
      top: 10px;
    }

    .viewMoreWithLogo {
      padding: 10px;
    }

    .viewMorelogo {
      width: 122px;
      height: 30px;
      position: relative;
      top: 2px;
    }

    .viewMoreButton {
      font-size: 14px;
      height: 30px;
      padding: 0 12px;
    }
  }

  .smallTabs {
    .tabSelector {
      font-size: 13px;
      padding-left: 8px;
      padding-right: 8px;
      min-width: 76px;
    }
  }

  &.wide {
    .content {
      padding-top: 74px;
      padding-bottom: 60px;
    }

    .tabContainer {
      top: 20px;
    }

    .tabSelector {
      font-size: 18px;
      height: 32px;
    }

    .viewMoreWithLogo {
      padding: 12px;
    }

    .viewMorelogo {
      width: 162px;
      height: 40px;
      position: relative;
      top: 3px;
    }

    .viewMoreButton {
      font-size: 16px;
      width: 220px;
      height: 40px;
    }
  }

  .app:hover .viewMoreButton {
    background-color: #9b102b;
  }
}

:global(.mol-mobile .infinite-scroll) {
  .article.previewWrapper {
    padding: 10px;
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 0;
  }

  .headline {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .previewText {
    margin-top: 8px;
  }

  .promo.previewWrapper {
    margin: 10px;
  }
}

:global(.article-page .mol-mobile #mobile-content) {
  .previewWrapper {
    margin-left: 10px;
    margin-right: 10px;
  }
}

:global(.article-page .mol-mobile #mobile-content .scrollable-content > article) {
  .previewWrapper {
    margin-left: 0;
    margin-right: 0;
  }
}

.mpu,
.mpuSmall,
.bellyband {
  .app {
    margin-bottom: 0;
  }

  &.article {
    &.previewWrapper {
      margin-bottom: 0;
    }

    &.narrow {
      &.previewWrapper {
        border: 1px solid #aaa;
        margin-bottom: 0;
      }

      .headline {
        padding: 10px;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .content {
        border: none;
      }

      .viewMoreWithLogo {
        width: calc(100% + 2px);
        left: -1px;
        bottom: -1px;

        &::after {
          display: none;
          width: calc(100% - 1px);
          left: 0;
        }
      }
    }
  }

  &.promo {
    &.previewWrapper {
      margin-bottom: 0;
    }

    &.narrow,
    &.wide {
      .logo {
        width: 120px;
        height: 30px;
      }

      .heading {
        padding-top: 6px;
        padding-bottom: 4px;
        justify-content: left;
        background-color: #000;
      }

      .subheading {
        width: auto;
        font-size: 14px;
      }

      .viewMore {
        background-color: #de2148;
        font-size: 16px;
        border-top: none;
        height: 40px;
        line-height: 19px;
        bottom: 0;
        left: 0;
        width: 100%;
      }

      .app:hover .viewMore {
        background-color: #ce1138;
      }

      .viewMoreChevron {
        width: 12px;
        height: 19px;
      }
    }

    &.narrow {
      .tabSelector {
        height: 23px;
        min-width: auto;
      }

      .tabContainer {
        top: 50px;
      }

      .content {
        padding-top: 46px;
      }
    }

    &.wide {
      .heading {
        padding-left: 10px;
      }

      .content {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 54px;
      }

      .tabContainer {
        top: 50px;
        left: 11px;
        width: calc(100% - 21px);
      }

      .tabSelector {
        font-size: 18px;
        height: 32px;
      }
    }
  }
}

.mpuSmall {
  &.article {
    &.narrow {
      .headline {
        font-size: 16px;
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 6px;
        line-height: 1.2;
      }

      .tabContainer {
        left: 7px;
        width: calc(100% - 14px);
      }

      .content {
        padding-left: 6px;
        padding-right: 6px;
        padding-bottom: 16px;
      }

      .viewMoreWithLogo {
        padding: 4px;
      }
    }
  }

  &.promo {
    &.narrow {
      .tabContainer {
        top: 46px;
        width: calc(100% - 14px);
        left: 7px;
      }

      .content {
        padding-top: 38px;
        padding-left: 6px;
        padding-right: 6px;
      }
    }
  }
}

.bellyband {
  &.wide.previewWrapper {
    width: calc(100% - 5px);
  }

  &.article {
    &.wide {
      &.previewWrapper {
        display: flex;
        flex-direction: row;
        border: 1px solid #aaa;
      }

      .appWrapper {
        width: 500px;
        flex-shrink: 0;
      }

      .content {
        padding-left: 0;
        border: none;
        padding-top: 54px;
        padding-bottom: 30px;
      }

      .tabContainer {
        width: 100%;
        left: 0;
        top: 12px;
      }

      .headline {
        width: auto;
        margin-top: 12px;
        margin-left: 20px;
        margin-right: 20px;
      }

      .viewMoreWithLogo {
        width: calc(100% + 2px);
        left: -1px;
        bottom: -1px;

        &::after {
          display: none;
        }
      }
    }
  }
}

.mpu {
  &.narrow {
    &.article {
      .viewMoreWithLogo {
        position: absolute;
        font-family: Arial, sans-serif;
        bottom: 0;
      }

      &:hover .viewMoreButton {
        background-color: #ce1138;
      }

      &.previewWrapper {
        position: static;
        max-height: 600px;
        overflow: hidden;
      }
    }

    &.promo {
      /* stylelint-disable-next-line no-descending-specificity */
      .viewMore {
        position: absolute;
        font-family: Arial, sans-serif;
        bottom: 0;
      }

      /* stylelint-disable-next-line no-descending-specificity */
      &:hover .viewMore {
        background-color: #ce1138;
      }

      &.previewWrapper {
        position: static;
        max-height: 600px;
        overflow: hidden;
      }
    }
  }
}

.mobileStyle {
  &.article {
    &.narrow {
      .headline {
        padding: 0;
        margin-bottom: 0;
      }

      .tabContainer {
        left: 0;
        width: 100%;
      }

      .tabSelector {
        font-size: 11px;
        min-width: auto;
      }

      .content {
        border: none;
        padding-bottom: 60px;
        padding-left: 0;
        padding-right: 0;
      }

      .viewMoreWithLogo {
        width: calc(100% + 2px);
        left: -1px;
        bottom: -1px;

        &::after {
          display: none;
          width: calc(100% - 1px);
          left: 0;
        }
      }
    }
  }

  &.promo {
    &.narrow {
      .tabSelector {
        height: 23px;
        min-width: auto;
      }

      .content {
        padding-top: 50px;
        padding-bottom: 50px;
      }

      /* stylelint-disable-next-line no-descending-specificity */
      .viewMore {
        background-color: #de2148;
        font-size: 16px;
        border-top: none;
        height: 40px;
        line-height: 19px;
        bottom: 0;
        left: 0;
        width: 100%;
      }

      .viewMoreChevron {
        height: 12px;
        width: 8px;
      }
    }
  }
}

@media (min-width: 370px) {
  .mobileStyle {
    &.article {
      &.narrow {
        .tabSelector {
          font-size: 14px;
          min-width: auto;
        }
      }
    }
  }
}

@media (max-width: 330px) {
  .mobileStyle {
    &.narrow {
      .tabSelector {
        font-size: 10px;
      }
    }
  }
}

/* stylelint-disable no-descending-specificity */
.darkMode {
  &.previewWrapper {
    background: #333;
  }

  .app {
    background: #333;
  }

  .content {
    border: 1px solid #333;
  }

  .heading {
    background-color: #1b1b1b;
  }

  .buttonSelectors {
    &[data-current-tab='news'] {
      border-color: #000;
    }

    &[data-current-tab='sport'] {
      border-color: #002816;
    }

    &[data-current-tab='entertainment'] {
      border-color: #2b010a;
    }

    &[data-current-tab='newYork'] {
      border-color: #000080;
    }
  }

  .tabSelector {
    &[data-tab-name='news'] {
      background-color: #000;
    }

    &[data-tab-name='sport'] {
      background-color: #002816;
    }

    &[data-tab-name='entertainment'] {
      background-color: #2b010a;
    }

    &[data-tab-name='newYork'] {
      background-color: #000080;
    }
  }

  &.narrow.promo {
    .content {
      background-color: #333;
    }

    .viewMore {
      background-color: #a70829;
    }
  }
}

.compactMode,
.narrow.promo.compactMode,
.wide.compactMode,
.mobileStyle.narrow.promo.compactMode {
  .content {
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    min-height: 196px;
    align-items: center;
  }

  .viewMore {
    position: static;
    width: calc(100% - 20px);
    margin: 34px auto 0;
    border-top: none;
    height: 40px;
    border-radius: 2px;
    background-color: #de1842;
    font-size: 14px;
    font-weight: bold;

    &:hover {
      background-color: #be0822;
    }
  }

  .heading {
    padding-bottom: 2px;
    padding-top: 6px;
    padding-left: 4px;
  }

  .logo {
    width: 140px;
    height: 35px;
    margin-right: 8px;
  }

  .subheading {
    width: 60px;
    font-size: 13px;
    padding-bottom: 2px;
  }
}
/* stylelint-enable no-descending-specificity */
